import "bootstrap";
import './edit_mode';
import 'slick-carousel';

console.log('Hello from webpack!');

$('.banner-carousel').slick({
  prevArrow: '<i class="fas fa-angle-left"></i>',
  nextArrow: '<i class="fas fa-angle-right"></i>',
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
});

const mapElement = document.getElementById('map');
const popup = new mapboxgl.Popup({closeOnClick: false}).setHTML(
  "<h5>Pichita Lanna</h5><p>Rua Salinas 620, Floresta, Belo Horizonte, Minas Gerais, Orçamentos e encomendas: 3489-5300 e 3262-0768</p>"
);

if (mapElement) {
  mapboxgl.accessToken = mapElement.dataset.mapboxApiKey;
  const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v10',
    zoom: 16,
    center: [-43.9304315, -19.9098683],
  });
  new mapboxgl.Marker({color: "#9a0000"})
    .setLngLat([-43.9304315, -19.9098683])
    .setPopup(popup)
    .addTo(map);
}
